import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { CircularProgress, Typography, Button } from '@mui/material';
import { format } from 'date-fns';
import LeadTimesTable from './LeadTimesTable';
import LeadTimesCharts from './LeadTimesCharts';
import RouteLeadTimeChart from './RouteLeadTimeChart';
import CloropethContainer from "../../../components/Cloropeth/Container";
import DashboardHorizontalBarChart from "../../../components/Graphics/HorizontalChart/DashboardHorizontalChart";
import { getLeadTimes, getSpendByConcept, getSpendByCountry } from "../../../services/kpis.services";

const LeadTimesPage = () => {
  const [leadTimes, setLeadTimes] = useState(null);
  const [spendByConcept, setSpendByConcept] = useState(null);
  const [spendByCountry, setSpendByCountry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorLeadTimes, setErrorLeadTimes] = useState(null);
  const [errorSpendByConcept, setErrorSpendByConcept] = useState(null);
  const [errorSpendByCountry, setErrorSpendByCountry] = useState(null);
  const [showCharts, setShowCharts] = useState(true);
  const token = useSelector((state) => state.user.token);

  const [dates, setDates] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1), // First day of current month
    endDate: new Date(),
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const [leadTimesRes, spendByConceptRes, spendByCountryRes] = await Promise.all([
        getLeadTimes(token),
        // getSpendByConcept(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
        getSpendByCountry(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd"))
      ]);

      const leadTimesData = await leadTimesRes.json();
      // const spendByConceptData = await spendByConceptRes.json();
      const spendByCountryData = await spendByCountryRes.json();

      setLeadTimes(leadTimesData);
      // setSpendByConcept(spendByConceptData.spendbyconcept);
      setSpendByCountry(spendByCountryData);
      setErrorLeadTimes(null);
      // setErrorSpendByConcept(null);
      setErrorSpendByCountry(null);
    } catch (err) {
      if (err.message.includes("LeadTimes")) {
        setErrorLeadTimes('Failed to fetch Lead Times data: ' + err.message);
      } else if (err.message.includes("SpendByConcept")) {
        // setErrorSpendByConcept('Failed to fetch Spend By Concept data: ' + err.message);
      } else if (err.message.includes("SpendByCountry")) {
        setErrorSpendByCountry('Failed to fetch Spend By Country data: ' + err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dates]);

  const toggleCharts = () => {
    setShowCharts(!showCharts);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }
  // const spendByConceptChartData = spendByConcept ? Object.entries(spendByConcept)
  // .map(([key, value]) => ({ name: key, Profit: value }))
  // .sort((a, b) => b.Profit - a.Profit) : [];

  return (
    
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Services Dashboard</h1>
        <Button 
          onClick={toggleCharts} 
          variant="contained" 
          color="primary"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {showCharts ? 'Hide Charts' : 'Show Charts'}
        </Button>
      </div>
      
      {showCharts && (
        <>
          {leadTimes && !errorLeadTimes && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">Lead Times Overview</h2>
              <LeadTimesCharts data={leadTimes} />
            </div>
          )}
          
          {leadTimes && !errorLeadTimes && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">Individual Route Lead Times</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {Object.entries(leadTimes).map(([routeName, routeData]) => (
                  <RouteLeadTimeChart key={routeName} routeName={routeName} routeData={routeData} />
                ))}
              </div>
            </div>
          )}

          {spendByCountry && !errorSpendByCountry && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">Spend by Country</h2>
              <CloropethContainer title={"Spend by Country Map"} data={spendByCountry} icon="Maritimo"/>
            </div>
          )}

          {/* {spendByConcept && !errorSpendByConcept && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">Spend by Concept</h2>
              <div style={{ width: "100%", minHeight:'600px', margin: "auto" }}>
                {Object.entries(spendByConcept).length > 0 && 
                  <DashboardHorizontalBarChart dataKey={"Profit"} data={spendByConceptChartData}/>
                }
              </div>
            </div>
          )} */}
        </>
      )}
      
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Lead Times Table</h2>
        {errorLeadTimes ? (
          <div className="flex flex-col items-center justify-center">
            <Typography color="error" className="mb-4">{errorLeadTimes}</Typography>
            <Button onClick={() => fetchData()} variant="contained" color="primary">
              Retry
            </Button>
          </div>
        ) : (
          <LeadTimesTable data={leadTimes} />
        )}
      </div>
    </div>
  );
};

export default LeadTimesPage;
