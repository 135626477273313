import React from "react";
import { styled } from "@nextui-org/react";
import { useFormContext, Controller, useFormState } from "react-hook-form";
import Select from "react-select";
import { Tooltip } from "@mui/material";

const customStyles = {
  control: () => ({
    display: "flex",
    fontSize: "14px",
    padding: "0",
    cursor: "pointer",
    margin: "5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    transition: "box-shadow 0.3s ease",
    background: "#f7f7f7",
    minWidth: "190px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
    fontSize: "13px",
    display: "flex",
  }),
};

export default function SmallSelect({
  name,
  label,
  options,
  required = true,
  labelOnTop = false,
}) {
  const Container = styled("div", {
    padding: "0 5px",
    width: "100%",
    display: "flex",
    flexDirection: labelOnTop ? "column" : "row",
    justifyContent: "space-between",
    alignItems: labelOnTop ? "left" : "center",
    ["label"]: {
      fontSize: "14px",
      marginLeft: labelOnTop ? "5px" : 0,
    },
    ["select"]: {
      minWidth: "190px",
      fontSize: "14px",
      border: "1px solid #ccc",
      ["&:focus"]: {
        outline: "1px solid #ccc",
        border: "1px solid #ccc",
      },
    },
    ".inputError": {
      display: "flex",
      flexDirection: "column",
      span: {
        fontSize: "12px",
      },
    },
  });

  const { control } = useFormContext();
  const errors = useFormState()?.errors;
  const splitError = name.split(".");
  const thisFieldError = errors[splitError[0]]
    ? errors[splitError[0]][splitError[1]]
    : "";

  return name ? (
    <Controller
      name={name}
      defaultValue=""
      control={control}
      rules={required ? { required: "Este campo es obligatorio" } : {}}
      render={({ field }) => (
        <Container>
          <div className="flex justify-between w-[100%] items-center">
            <Tooltip title="Este campo es obligatorio" arrow>
              <label htmlFor={name}>
                {label}
                {required && <span style={{ cursor: "pointer" }}>*</span>}:
              </label>
            </Tooltip>
            <div className="inputError w-[50%]">
              <Select
                {...field}
                options={options}
                isSearchable={true}
                placeholder="Select an option"
                styles={customStyles}
                hideSelectedOptions
              />
              {thisFieldError && (
                <span style={{ color: "red" }}>{thisFieldError?.message}</span>
              )}
            </div>
          </div>
        </Container>
      )}
    />
  ) : (
    <Container>
      <label style={{ fontSize: "11px" }}>{label}</label>
      <Select
        options={options}
        isSearchable={true}
        placeholder="Select an option"
        styles={customStyles}
        hideSelectedOptions
      />
    </Container>
  );
}
