import React, { useEffect, useRef } from "react";
import { useState } from "react";
import InputTable from "../../../../components/InputTable/InputTable";
import { useForm, useFormContext } from "react-hook-form";

export default function PackagingList({ trigger, errorsPackaging, setErrorPackaging}) {
  const tableName = "packaging_list";
  const methods = useFormContext();
  const [totalQty, setTotalQty] = useState(0);
  const [totalCbm, setTotalCbm] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const firstRender = useRef(true);
  const columns = [,
    { name: "Descripcion", type: "text" },
    { name: "Qty", type: "number" },
    { name: "Largo(m)", type: "number" },
    { name: "Ancho(m)", type: "number" },
    { name: "Alto(m)", type: "number" },
    { name: "Volumen por pieza(m³)", type: "number" },
    { name: "CBM(m³)", type: "number" },
    { name: "Peso por pieza(Kg)", type: "number" },
    { name: "Peso total(Kg)", type: "number" },
    {
      name: "Estiba",
      type: "select",
      options: [
        { label: "SI", value: true },
        { label: "No", value: false },
      ],
    },
  ];

  const defaultData = {
    itemId: 9999,
    Descripcion: "",
    Qty: "",
    "Largo(m)": "",
    "Ancho(m)": "",
    "Alto(m)":"",
    "Volumen por pieza": "",
    CBM: "",
    "Peso por pieza(Kg)": "",
    "Peso total": "",
    Estiba: "",
  };

  function calculateRowValues(row) {
    const qty = parseFloat(row.Qty);
    const ancho = parseFloat(row["Ancho(m)"]);
    const largo = parseFloat(row["Largo(m)"]);
    const alto = parseFloat(row["Alto(m)"]);
    const pesoPorPieza = parseFloat(row["Peso por pieza(Kg)"]);

    if (
      !isNaN(qty) &&
      !isNaN(ancho) &&
      !isNaN(largo) &&
      !isNaN(alto) &&
      !isNaN(pesoPorPieza)
    ) {
      const volume = ancho * largo * alto; // Volumen por pieza
      const CBM = volume * qty; // Volumen total (CBM)
      const totalWeight = pesoPorPieza * qty;

      return {
        volume: volume.toFixed(2),
        CBM: CBM.toFixed(2),
        totalWeight: totalWeight.toFixed(2),
      };
    }
    return { volume: 0, CBM: 0, totalWeight: 0 };
  }

  function setTotals() {
    let tableTotalQty = 0;
    let tableTotalCbm = 0;
    let tableTotalWeight = 0;
    const items = methods.getValues(tableName);

    items?.forEach((row, index) => {
      const { volume, CBM, totalWeight } = calculateRowValues(row);

      tableTotalQty += parseFloat(row.Qty) || 0;
      tableTotalCbm += parseFloat(CBM) || 0;
      tableTotalWeight += parseFloat(totalWeight) || 0;

      if (
        methods.getValues(`${tableName}[${index}].Volumen por pieza(m³)`) !==
        volume
      ) {
        methods.setValue(
          `${tableName}[${index}].Volumen por pieza(m³)`,
          volume
        );
      }
      if (methods.getValues(`${tableName}[${index}].CBM(m³)`) !== CBM) {
        methods.setValue(`${tableName}[${index}].CBM(m³)`, CBM);
      }
      if (
        methods.getValues(`${tableName}[${index}].Peso total(Kg)`) !==
        totalWeight
      ) {
        methods.setValue(`${tableName}[${index}].Peso total(Kg)`, totalWeight);
      }
    });

    setTotalQty(tableTotalQty);
    setTotalCbm(tableTotalCbm);
    setTotalWeight(tableTotalWeight);

    // Solo actualiza si hay cambios en los valores
    if (methods.getValues("shipping_information.volume") !== tableTotalCbm) {
      methods.setValue("shipping_information.volume", tableTotalCbm);
    }
    if (methods.getValues("total_weight_kg") !== tableTotalWeight) {
      methods.setValue("total_weight_kg", tableTotalWeight);
    }
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setTotals();
  }, [methods.watch()]);
  return (
    <div>
      <InputTable
        tableName={tableName}
        columns={columns}
        addRowText="Agregar carga +"
        defaultData={defaultData}
        errors={errorsPackaging}
        setErrorTabla={setErrorPackaging}
      />
      {Object.keys(errorsPackaging).length > 0 && (
        <div className="flex justify-end items-center py-2">
          <p className="text-red-700 text-end text-xs">
            Los siguientes datos no pueden ser cero o estar vacios: {Object.keys(errorsPackaging).map(
              (key, idx) =>
                `${errorsPackaging[key]}${
                  idx < Object.keys(errorsPackaging).length - 1 ? ", " : ""
                }`
            )}
          </p>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <h4>Total Qty : {totalQty}</h4>
        <h4>Total Cmb : {totalCbm}</h4>
        <h4>Total Weight : {totalWeight?.toFixed(2)}</h4>
      </div>
    </div>
  );
}
