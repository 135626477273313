import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LeadTimesCharts = ({ data }) => {
  const prepareChartData = () => {
    return Object.entries(data).map(([route, times]) => ({
      route,
      'Port to Port': times.port_to_port.avg || 0,
      'Door to Port': times.door_to_port.avg || 0,
      'Port to Door': times.port_to_door.avg || 0,
      'Customs': times.customs.avg || 0,
    }));
  };

  const chartData = prepareChartData();

  return (
    <div className="lead-times-charts bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">Lead Times Comparison</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="route" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Port to Port" fill="#8884d8" />
          <Bar dataKey="Door to Port" fill="#82ca9d" />
          <Bar dataKey="Port to Door" fill="#ffc658" />
          <Bar dataKey="Customs" fill="#ff7300" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LeadTimesCharts;