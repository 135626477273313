import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const RouteLeadTimeChart = ({ routeData, routeName }) => {
  const chartData = [
    { name: 'Minimum', ...routeData },
    { name: 'Average', 
      'Port to Port': routeData.port_to_port.avg,
      'Door to Port': routeData.door_to_port.avg,
      'Port to Door': routeData.port_to_door.avg,
      'Customs': routeData.customs.avg
    },
    { name: 'Maximum', 
      'Port to Port': routeData.port_to_port.max,
      'Door to Port': routeData.door_to_port.max,
      'Port to Door': routeData.port_to_door.max,
      'Customs': routeData.customs.max
    }
  ];

  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-6">
      <h3 className="text-xl font-semibold mb-4">{routeName}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Port to Port" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Door to Port" stroke="#82ca9d" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Port to Door" stroke="#ffc658" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Customs" stroke="#ff7300" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RouteLeadTimeChart;