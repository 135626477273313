import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Copyright from "../../../components/Copyright/Copyright.jsx";

// Material-UI imports
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  InputAdornment,
  IconButton,
  Tooltip,
  Alert,
  Snackbar,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EditIcon from "@mui/icons-material/Edit";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Import your actions
import { loginAction } from "../../../store/sessionDuck.js";
import { setTenantAction } from "../../../store/tenant.js";

const defaultTheme = createTheme();

function LoginTwo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant);
  const { register, handleSubmit } = useForm();
  const logo = useSelector((state) => state.tenant.logo);
  const [searchParams] = useSearchParams();
  const email = decodeURIComponent(searchParams.get("mail") || "");
  const tenantName = searchParams.get("tenant");
  const { t: translate, i18n } = useTranslation("Login");

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (tenantName) {
      dispatch(setTenantAction(tenantName));
    }
  }, [tenantName, dispatch]);

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    const langToUse = userLang.startsWith("es") ? "es_CO" : "en_US";
    i18n.changeLanguage(langToUse);
  }, [i18n]);

  const doLogin = async (credentials) => {
    setIsLoading(true);
    setError(null);

    try {
      await dispatch(loginAction({ ...credentials, email, navigate }));
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);

      if (error.response) {
        switch (error.response.status) {
          case 400:
            setError(translate("invalid_credentials"));
            break;
          case 401:
            setError(translate("unauthorized"));
            break;
          case 403:
            setError(translate("account_locked"));
            break;
          default:
            setError(translate("unknown_error"));
        }
      } else if (error.request) {
        setError(translate("network_error"));
      } else {
        setError(translate("configuration_error"));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    localStorage.removeItem("tenantName");
    localStorage.removeItem("storage");
    navigate("/");
  };

  const handleEditEmail = () => {
    navigate("/");
  };

  const toggleLanguage = () => {
    const newLang = i18n.language === "en_US" ? "es_CO" : "en_US";
    i18n.changeLanguage(newLang);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            component="video"
            autoPlay
            muted
            loop
            playsInline
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source
              src="https://videos.pexels.com/video-files/10529628/10529628-hd_2560_1440_24fps.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              component="a"
              href="https://www.facebook.com/profile.php?id=61551026647484"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", mr: 1 }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://x.com/val_logistics"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", mr: 1 }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.linkedin.com/company/val-logistics"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white" }}
            >
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            background: "linear-gradient(145deg, #f3f3f3 0%, #e6e6e6 100%)",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={translate("toggle_language")} placement="left">
            <IconButton
              onClick={toggleLanguage}
              color="primary"
              sx={{
                position: "absolute",
                top: "20px",
                right: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
            >
              <LanguageIcon />
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 70,
                mb: 2,
              }}
              alt="Logo"
              src={logo}
            />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(doLogin)}
              sx={{ width: "100%", maxWidth: "400px" }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="email"
                value={email}
                label={translate("user")}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleEditEmail}>
                        <EditIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 1 }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={translate("password")}
                type="password"
                id="password"
                autoComplete="current-password"
                placeholder={translate("password_placeholder")}
                {...register("password", { required: true })}
                sx={{ mb: 1 }}
              />
              <Link
                component={RouterLink}
                to="/forgot-password"
                variant="body2"
                sx={{ fontSize: "0.8rem" }}
              >
                {translate("forgot_mypassword")}
              </Link>
              <Box
                sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  sx={{ mt: 1, mb: 1, width: "45%" }}
                >
                  {translate("back")}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  sx={{ mt: 1, mb: 1, width: "45%" }}
                >
                  {isLoading ? translate("logging_in") : translate("login")}
                </Button>
              </Box>
              <Grid container>
                <Grid item>
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="body2"
                    sx={{ fontSize: "0.8rem" }}
                  >
                    {translate("or_register")}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 2, fontSize: "0.7rem" }} />
            </Box>
          </Box>

          <Box
            sx={{
              mx: 4,
              mb: 4,
              textAlign: "left",
              color: "white",
              padding: "15px",
              borderRadius: "10px",
              background: "rgba(0, 0, 0, 0.6)",
              backdropFilter: "blur(5px)",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: "light",
                letterSpacing: "1px",
                textTransform: "uppercase",
                fontSize: "0.9rem",
              }}
            >
              {translate("discover_future")}
            </Typography>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                letterSpacing: "2px",
                textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                marginBottom: "10px",
                fontSize: "1.5rem",
              }}
            >
              VAL LOGISTICS
            </Typography>
            <Typography
              variant="body2"
              component="p"
              gutterBottom
              sx={{
                fontWeight: "medium",
                marginBottom: "10px",
                fontSize: "0.8rem",
              }}
            >
              {translate("driving_global")}
            </Typography>
            <Link
              href="https://val.com.co"
              color="inherit"
              underline="none"
              sx={{
                display: "inline-block",
                padding: "5px 10px",
                border: "1px solid white",
                borderRadius: "15px",
                transition: "all 0.3s ease",
                fontSize: "0.8rem",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
            >
              {translate("know_more")}
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default LoginTwo;
