import { styled } from '@nextui-org/react';
import TextEditor from '../../components/TextEditor/TextEditor'
import Button from '../../components/Button/Button'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getQuoteData, editQuotation, getVersions } from '../../services/quotations.services';
import toast from '../../components/Toast/ToastTypes';
import InputSelect from '../../components/Select/inputSelect';
import { useTranslation } from "react-i18next";

const StyledDiv = styled('div', {
    bgColor: '$neutral_50',
    borderRadius: '8px',
    boxShadow:'$elevation_1',
    padding:'15px',
    '.controls':{
        margin:'10px 0',
        display:'flex',
        justifyContent:'space-between',
        "@media(max-width:500px)":{
            flexDirection:'column',
        }
    }
})

export default function Notes({ currentQuote, setCurrentQuote }) {

    const [versions, setVersions] = useState()
    const [selectedVersion, setSelectedVersion] = useState()

    const [notesHTML, setNotesHTML] = useState('')
    const [readOnly, setReadOnly] = useState(true)
    const [quoteData, setQuoteData] = useState({})
    const token = useSelector(state => state.user.token)

    useEffect(() => {
        Promise.all([getQuoteData(token, currentQuote?.id), getQuoteData(token)]).then(async res => {
            const liquidationData = await res[0].json()
            const allLiquidationsData = await res[1].json()

            setReadOnly(true)
            setQuoteData(liquidationData)
            setNotesHTML(liquidationData.observations)

            const versionsData = allLiquidationsData.filter(liquidation => liquidation.radication === liquidationData.radication)
            const sortedVersions = versionsData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            setVersions(sortedVersions)
            const selectedVersionIndex = sortedVersions.findIndex(version => version.id === currentQuote.id)
            setSelectedVersion({
                label:'Versión '+ (selectedVersionIndex+1),
                value: currentQuote?.id
            })
        }).catch(err => console.log('err :', err))
    }, [currentQuote])

    const handleSubmit = () => {
        const data = quoteData
        data.observations = notesHTML

        data.origin_terminal = 'origenTepm'
        data.destination_terminal = 'destinoTepm'

        editQuotation(data, quoteData.id, token).then(async res => {
            toast('success', 'Nota modificada con exito')
            setReadOnly(true)
        }).catch(err => console.log('err :', err))
    }

    function handleChangeVersion(e){
        setCurrentQuote({
          radication:currentQuote.radication,
          id:e.value,
        })
        setSelectedVersion(e)
    }

    return (
        <div>
            <StyledDiv>
                <div className='controls'>
                    <div style={{ display: 'flex', gap: '15px', margin:'15px' }}>
                    <Button text='Modificar' size="sm" bgColor={'primary_300'} textColor='neutral_50' handler={() => setReadOnly(false)} disabled={!readOnly || !currentQuote} endIcon={{name:'EditSquare', color:'white'}}/>
                    <Button text='Guardar' size="sm" bgColor={'primary_300'} textColor='neutral_50' handler={() => handleSubmit()} disabled={readOnly || !currentQuote} endIcon={{name:'CheckSquare', color:'white'}}/>
                    </div>
                    <InputSelect
                        label="versiones"
                        disableClear
                        isDisabled={!currentQuote}
                        onChange={(e)=>handleChangeVersion(e)}
                        value={selectedVersion}
                        options={versions?.map((ver,i) => {
                        return {
                            label:'Versión ' + (i+1),
                            value:ver.id,
                        }
                        })}
                    />
                </div>
                <TextEditor state={notesHTML} setState={setNotesHTML} readOnly={readOnly} />
            </StyledDiv>
        </div>
    )
}