import React, { useEffect, useState } from "react";
import { styled, Tooltip } from "@nextui-org/react";
import { useFormContext } from "react-hook-form";
import SmallSelectTable from "../../pages/ManageQuotes/PreLiquidation/CreatePreliquidation/Fields/SmallSelectTable";
import AutocompleteInput from "./AutocompleteInput"; // Asegúrate de importar el componente
import { IconButton } from "@mui/material";
import { MdDeleteForever } from "react-icons/md";

const Container = styled("div", {
  width: "100%",
  overflow: "visible",
  margin: "0 auto",
  fontFamily: "Arial, sans-serif",
  fontSize: "13px",
  "@media(max-width:1200px)": {
    overflowX: "auto",
  },
});

const Table = styled("table", {
  overflow: "scroll",
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "20px",
  "& th, & td": {
    border: "1px solid $neutral_200",
    textAlign: "left",
    margin: 0,
  },
  "& th": {
    backgroundColor: "#f0f0f0",
    padding: "10px",
    paddingLeft: "15px",
    fontWeight: "bold",
    border: "1px solid $neutral_200",
  },
  "& tr:nth-child(even)": {
    backgroundColor: "#f9f9f9",
  },
});

const Input = styled("input", {
  width: "100%",
  padding: "8px",
  borderRadius: "4px",
  background: "transparent",
  border: "none",
  transition: "border-color 0.3s ease",
  "&:focus": {
    outline: "none",
    borderColor: "#4CAF50",
  },
});

const Button = styled("button", {
  padding: "2px",
  width: "30%",
  borderRadius: "4px",
  border: "none",
  backgroundColor: "#3c529c",
  color: "#fff",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "#4f619d",
  },
});

const DeleteButton = styled("button", {
  padding: "5px 5px 0px 5px",
  margin: "5px",
  marginLeft: "22px",
  borderRadius: "4px",
  border: "none",
  backgroundColor: "$neutral_300",
  color: "#fff",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "$delete",
  },
});
export default function InputTable({
  tableName,
  columns,
  defaultData,
  addRowText = "Agregar Concepto +",
  enableAddDelete = true,
  errors = {},
  setUnit,
  setErrorTabla,
}) {
  const { getValues, setValue } = useFormContext();
  const methods = useFormContext();
  const [data, setData] = useState(getValues(tableName));

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name?.startsWith(tableName)) {
        const newData = value[tableName];
        setData([...newData]);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  const columnNameMap = {
    "Largo(m)": "Largo",
    "Ancho(m)": "Ancho",
    "Alto(m)": "Alto",
    "Peso por pieza(Kg)": "Peso por pieza",
    "Peso total(Kg)": "Peso total",
    "Volumen por pieza(m³)": "Volumen por pieza",
    "CBM(m³)": "CBM",
    Descripcion: "Descripcion",
    Qty: "Qty",
  };

  const onChangeInput = (e, itemId) => {
    const { name, value } = e.target;
    const normalizedName = columnNameMap[name] || name;

    const editData = data.map((item) =>
      item.itemId === itemId && normalizedName
        ? { ...item, [normalizedName]: value }
        : item
    );

    if (editData[itemId - 1]) {
      setValue(
        `${tableName}[${itemId - 1}].${name}`,
        editData[itemId - 1][normalizedName]
      );

      if (
        tableName === "packaging_list" &&
        (value === "0" || value === "" || parseFloat(value) < 0)
      ) {
        if (
          !["Volumen por pieza(m³)", "CBM(m³)", "Peso total(Kg)"].includes(name)
        ) {
          console.log(
            `No puede ir cero en la celda "${name}" del item con ID ${itemId}`
          );
          setErrorTabla((prevErrors) => ({
            ...prevErrors,
            [`${tableName}[${itemId - 1}].${name}`]: `${normalizedName}`,
          }));
        }
      } else {
        // Limpiar el error si el valor es válido
        setErrorTabla((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[`${tableName}[${itemId - 1}].${name}`];
          return newErrors;
        });
      }
    }
  };

  const onChangeAutocomplete = (e, itemId) => {
    const { name, value, unit } = e.target;
    setUnit(unit, itemId);
    const editData = data.map((item) =>
      item.itemId === itemId && name ? { ...item, [name]: value } : item
    );
    if (editData[itemId - 1]) {
      setValue(
        `${tableName}[${itemId - 1}].${name}`,
        editData[itemId - 1][name]
      );
    }
    // Maneja los valores aquí según tus necesidades
  };

  const onChangeSelect = (value, name, itemId) => {
    const editData = data.map((item) =>
      item.itemId === itemId && name ? { ...item, [name]: value } : item
    );
    setValue(`${tableName}[${itemId - 1}].${name}`, editData[itemId - 1][name]);
  };

  function addRow() {
    const newRow = {
      ...defaultData,
      itemId: data.length + 1,
    };

    setValue(tableName, [...data, newRow]);

    // Validar la nueva fila
    Object.keys(newRow).forEach((name) => {
      const value = newRow[name];
      if (
        tableName === "packaging_list" &&
        (value === "0" || value === "" || parseFloat(value) < 0) &&
        !["Volumen por pieza", "CBM", "Peso total", "Estiba"].includes(name)
      ) {
        console.log(
          `No puede ir cero en la celda "${name}" del item con ID ${newRow.itemId}`
        );
        setErrorTabla((prevErrors) => ({
          ...prevErrors,
          [`${tableName}[${newRow.itemId - 1}].${name}`]: `${name}`,
        }));
      }
    });
  }

  function deleteRow(itemId) {
    const newData = data.filter((item) => item.itemId !== itemId);

    const formattedIds = newData.map((item, i) => ({ ...item, itemId: i + 1 }));

    setValue(tableName, formattedIds);

    setErrorTabla((prevErrors) => {
      const newErrors = { ...prevErrors };
      Object.keys(newErrors).forEach((key) => {
        if (key.startsWith(`${tableName}[${itemId - 1}]`)) {
          delete newErrors[key];
        }
      });
      return newErrors;
    });
  }

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>ItemID</th>
            {columns.map((header) => (
              <th key={header.name}>{header.name}</th>
            ))}
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {data
            ?.filter((e) => e)
            ?.map(({ itemId, ...rest }) => {
              return (
                <tr key={itemId}>
                  <td>{itemId}</td>
                  {columns.map((column) => {
                    const cellErrorKey = `${tableName}[${itemId - 1}].${
                      column.name
                    }`;
                    const hasError = errors[cellErrorKey];
                    return (
                      <td key={column.name}>
                        {column.type === "conceptAutocomplete" ? (
                          <AutocompleteInput
                            name={column.name}
                            value={rest[column.name]}
                            itemId={itemId}
                            onChange={onChangeAutocomplete}
                            placeholder={`${column.name}...`}
                          />
                        ) : column.type !== "select" ? (
                          column.tooltip ? (
                            <Tooltip
                              placement="topEnd"
                              content={
                                <div>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      textWrap: "nowrap",
                                    }}
                                  >
                                    {`${rest.Unit?.tooltip || "..."}(unit) x ${
                                      rest.Factor || "..."
                                    }(factor) x ${
                                      rest.Rate || rest.Net || "..."
                                    }(rate/Net) / ${rest.Trm || "..."}(trm)`}
                                  </p>
                                  {!isNaN(rest.Unit?.weight) && (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textWrap: "nowrap",
                                      }}
                                    >
                                      Peso : {rest.Unit?.weight}
                                    </p>
                                  )}
                                  {!isNaN(rest.Unit?.volume) && (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textWrap: "nowrap",
                                      }}
                                    >
                                      Volumen :{rest.Unit?.volume}
                                    </p>
                                  )}
                                  {!isNaN(rest.Unit?.chargeableVol) && (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textWrap: "nowrap",
                                      }}
                                    >
                                      Volumen cargable :
                                      {rest.Unit?.chargeableVol}
                                    </p>
                                  )}
                                  {!isNaN(rest.Unit?.valueOfCargo) && (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textWrap: "nowrap",
                                      }}
                                    >
                                      Valor de la carga :
                                      {rest.Unit?.valueOfCargo}
                                    </p>
                                  )}
                                  {!isNaN(rest.Unit?.originTotal) && (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textWrap: "nowrap",
                                      }}
                                    >
                                      Total origen :{rest.Unit?.originTotal}
                                    </p>
                                  )}
                                  {!isNaN(rest.Unit?.freight) && (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textWrap: "nowrap",
                                      }}
                                    >
                                      Flete :{rest.Unit?.freight}
                                    </p>
                                  )}
                                  {!isNaN(rest.Unit?.freightTotal) && (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textWrap: "nowrap",
                                      }}
                                    >
                                      Total flete :{rest.Unit?.freightTotal}
                                    </p>
                                  )}
                                  {!isNaN(rest.Unit?.insuranceTotal) && (
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        textWrap: "nowrap",
                                      }}
                                    >
                                      Total seguro :{rest.Unit?.insuranceTotal}
                                    </p>
                                  )}
                                </div>
                              }
                              color="primary"
                            >
                              <Input
                                name={column.name}
                                value={rest[column.name]}
                                type={column.type}
                                onChange={(e) => onChangeInput(e, itemId)}
                                placeholder={`${column.name}...`}
                                onWheel={(e) => e.target.blur()}
                                style={
                                  hasError
                                    ? {
                                        border: "1px solid #b91c1c",
                                        backgroundColor:
                                          "rgba(255, 0, 0, 0.08)",
                                        borderRadius: "0px",
                                      } // Fondo rojo si hay error
                                    : {} // Sin fondo si no hay error
                                }
                              />
                            </Tooltip>
                          ) : (
                            <Input
                              name={column.name}
                              value={rest[column.name]}
                              type={column.type}
                              onChange={(e) => onChangeInput(e, itemId)}
                              placeholder={`${column.name}...`}
                              onWheel={(e) => e.target.blur()}
                              style={
                                hasError
                                  ? {
                                      border: "1px solid #b91c1c",
                                      backgroundColor: "rgba(255, 0, 0, 0.08)",
                                      borderRadius: "0px",
                                    }
                                  : {}
                              }
                            />
                          )
                        ) : (
                          <SmallSelectTable
                            name={column.name}
                            itemId={itemId}
                            value={rest[column.name]}
                            type="text"
                            onChange={onChangeSelect}
                            options={column.options}
                          />
                        )}
                      </td>
                    );
                  })}
                  <td>
                    {enableAddDelete && (
                      <div className=" flex justify-center items-center">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => deleteRow(itemId)}
                        >
                          <MdDeleteForever />
                        </IconButton>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {enableAddDelete && (
        <Button type="button" onClick={() => addRow()}>
          {addRowText}
        </Button>
      )}
    </Container>
  );
}
