import React from "react";
import { styled } from "@nextui-org/react";
import { useFormContext, Controller, useFormState } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";

const Container = styled("div", {
  padding: "0 5px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ["label"]: {
    fontSize: "14px",
  },
  ["input"]: {
    fontSize: "14px",
    padding: "8px",
    margin: "5px",
    borderRadius: "4px",
    transition: "box-shadow 0.3s ease",
    background: "#f7f7f7",
    minWidth: "190px",
    ["&:focus"]: {
      border: "1px solid #ccc",
    },
  },
  ".inputError": {
    display: "flex",
    flexDirection: "column",
    span: {
      fontSize: "12px",
    },
  },
});

export default function SmallField({
  name,
  label,
  disabled = false,
  bold = false,
  fontSize = "14px",
  type = "text",
  required = true,
  validateAsNumber = false,
}) {
  const { control } = useFormContext();
  const { errors } = useFormState();
  const splitError = name.split(".");
  const thisFieldError = errors[splitError[0]]
    ? errors[splitError[0]][splitError[1]]
    : "";

  const validationRules = {
    required: required ? { value: true, message: label } : false,
  };

  if (validateAsNumber) {
    validationRules.validate = {
      positive: (value) =>
        !isNaN(value) && value > 0 || `${label}`,
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      rules={validationRules}
      render={({ field }) => (
        <Container>
          <div className="flex w-[100%] justify-between items-center">
            <Tooltip title="Este campo es obligatorio" arrow>
              <label htmlFor={name}>
                {label}
                {required && <span style={{ cursor: "pointer" }}>*</span>}:
              </label>
            </Tooltip>
            <div className="inputError flex w-[50%]">
              <input
                type={type}
                step="any"
                id={name}
                name={name}
                {...field}
                style={{
                  border: thisFieldError ? "1px solid #b91c1c" : "1px solid #ccc",
                  backgroundColor: thisFieldError ? "rgba(255, 0, 0, 0.08)" : "",
                }}
              />
              {thisFieldError && (
                <span className="text-red-700 px-2 py-0 text-end">
                  {validateAsNumber
                    ? `${label} debe ser un número mayor a cero`
                    : `${label} es obligatorio`}
                </span>
              )}
            </div>
          </div>
        </Container>
      )}
    />
  );
}
